import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt_decode from 'jwt-decode'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  //ADMIN
  {
    path: '/admin/praktik',
    name: 'AdminPraktik',
    component: () => import('@/views/admin/AdminPraktik.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/praktik/anggota/:id',
    name: 'AdminPraktikAnggota',
    component: () => import('@/views/admin/AdminPraktikAnggota.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/user',
    name: 'User',
    component: () => import('@/views/admin/User.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //END ADMIN
  
  //USER
  {
    path: '/user/praktik',
    name: 'UserPraktik',
    component: () => import('@/views/user/UserPraktik.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/daftar_hadir/:praktik_id',
    name: 'UserDaftarHadir',
    component: () => import('@/views/user/UserDaftarHadir.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/jadwal_jaga/:praktik_id',
    name: 'UserJadwalJaga',
    component: () => import('@/views/user/UserJadwalJaga.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/logbook/:praktik_id',
    name: 'UserLogbook',
    component: () => import('@/views/user/UserLogbook.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/tingkat_supervisi/:praktik_id',
    name: 'UserTingkatSupervisi',
    component: () => import('@/views/user/UserTingkatSupervisi.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/laporan_jaga/:praktik_id',
    name: 'UserLaporanJaga',
    component: () => import('@/views/user/UserLaporanJaga.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/bst/:praktik_id',
    name: 'UserBst',
    component: () => import('@/views/user/UserBst.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/ujian/:praktik_id',
    name: 'UserUjian',
    component: () => import('@/views/user/UserUjian.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/jurnal_reading/:praktik_id',
    name: 'UserJurnalReading',
    component: () => import('@/views/user/UserJurnalReading.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //END USER

  // PEMBIMBING
  {
    path: '/pembimbing/pengembangan_diri',
    name: 'PembimbingPengembanganDiri',
    component: () => import('@/views/pembimbing/PembimbingPengembanganDiri.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/logbook/:praktik_id',
    name: 'PembimbingLogbook',
    component: () => import('@/views/pembimbing/PembimbingLogbook.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/verifikasi_daftar/:praktik_id',
    name: 'PembimbingVerifikasiDaftar',
    component: () => import('@/views/pembimbing/PembimbingVerifikasiDaftar.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/verifikasi_logbook/:praktik_id',
    name: 'PembimbingVerifikasiLogbook',
    component: () => import('@/views/pembimbing/PembimbingVerifikasiLogbook.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/verifikasi_tingkat/:praktik_id',
    name: 'PembimbingVerifikasiTingkat',
    component: () => import('@/views/pembimbing/PembimbingVerifikasiTingkat.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/evaluasi_dokter/:praktik_id',
    name: 'PembimbingEvaluasiDokter',
    component: () => import('@/views/pembimbing/PembimbingEvaluasiDokter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/evaluasi_ppds/:praktik_id',
    name: 'PembimbingEvaluasiPPDS',
    component: () => import('@/views/pembimbing/PembimbingEvaluasiPPDS.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pembimbing/evaluasi_lain/:praktik_id',
    name: 'PembimbingEvaluasiLain',
    component: () => import('@/views/pembimbing/PembimbingEvaluasiLain.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //END PEMBIMBING

  // UNIVERSITAS
  {
    path: '/universitas/praktik',
    name: 'UniversitasPraktik',
    component: () => import('@/views/universitas/UniversitasPraktik.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/universitas/praktik/anggota/:id',
    name: 'UniversitasPraktikAnggota',
    component: () => import('@/views/universitas/UniversitasPraktikAnggota.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/universitas/universitas_daftar/:praktik_id',
    name: 'UniversitasDaftar',
    component: () => import('@/views/universitas/UniversitasDaftar.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/universitas/evaluasi_dokter/:praktik_id',
    name: 'UniversitasEvaluasiDokter',
    component: () => import('@/views/universitas/UniversitasEvaluasiDokter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/universitas/evaluasi_ppds/:praktik_id',
    name: 'UniversitasEvaluasiPPDS',
    component: () => import('@/views/universitas/UniversitasEvaluasiPPDS.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/universitas/evaluasi_lain/:praktik_id',
    name: 'UniversitasEvaluasiLain',
    component: () => import('@/views/universitas/UniversitasEvaluasiLain.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //END UNIVERSITAS

  {
    path: '/kuesioner_link',
    name: 'KuesionerLink',
    component: () => import('@/views/pages/KuesionerLink.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/master/universitas',
    name: 'MasterUniversitas',
    component: () => import('@/views/master/MasterUniversitas.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/master/prodi',
    name: 'MasterProdi',
    component: () => import('@/views/master/MasterProdi.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/master/dokdiknis',
    name: 'MasterDokdiknis',
    component: () => import('@/views/master/MasterDokdiknis.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/master/kuesioner_link',
    name: 'MasterKuesionerLink',
    component: () => import('@/views/master/MasterKuesionerLink.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/master/stase',
    name: 'MasterStase',
    component: () => import('@/views/master/MasterStase.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (store.getters.loggedIn === false) {
    await store.dispatch('getToken')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.token == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      const tokenDecoded = jwt_decode(store.state.token)
      const user = tokenDecoded.data
      if (to.matched.some(record => record.meta.userCanAccess)) {
        if (to.meta.userCanAccess.includes(user.role)) {
          next()
        } else {
          next({ name: 'dashboard' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.state.token == null) {
      next()
    } else {
      next({ name: 'dashboard' })
    }
  } else {
    next()
  }
})

export default router
