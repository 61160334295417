import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
// import router from '../router'
import jwt_decode from 'jwt-decode'
import { axiosPost, axiosGet } from '@/utils/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userSelected: {},
    usersSelected: [],
  },
  getters: {
    loggedIn(state) {
      if (state.token === 'null' || state.token == null) return false
      if (state.token === 'undefined') return false

      return true
    },
    user(state) {
      const tokenDecode = jwt_decode(state.token)
      return tokenDecode.data
    },
    userSelected(state) {
      return state.userSelected
    },
  },
  mutations: {
    setDataLogin(state, data) {
      state.token = data.token
    },
    setUserSelected(state, data) {
      state.userSelected = data
    },
    setUsersSelected(state, data) {
      state.usersSelected = data
    },
  },
  actions: {
    login({ commit }, data) {
      commit('setDataLogin', data)
    },
    logout({ commit }, message = '') {
      axiosGet('Authentication/removeToken')
      alert(message + 'Anda keluar dari sistem')
      window.location.reload()
    },
    async getToken({ commit }) {
      const data = await axiosGet('Authentication/getToken')
      if (data.code == 200) {
        const dataLogin = {
          token: data.token,
        }
        commit('setDataLogin', dataLogin)
      }
    },
  },
  modules: {},
})
