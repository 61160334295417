import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.mixin({
  methods: {
    formatDate(input) {
      if (input === undefined || input === null || input === '') return input
      const datePart = input.match(/\d+/g)
      const year = datePart[0].substring(0, 4)
      const month = datePart[1]
      const day = datePart[2]

      return `${day}-${month}-${year}`
    },
    formatDateTime(input) {
      if (input === undefined || input === null || input === '') return input

      const datePart = input.match(/\d+/g)
      const year = datePart[0].substring(0, 4) // get only two digits
      const month = datePart[1]
      const day = datePart[2]
      const waktu = input.slice(11, 19)

      return `${day}-${month}-${year} ${waktu}`
    },
    formatMoney(input) {
      return new Intl.NumberFormat(['id']).format(input)
    },
    nl2br(str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },
  },
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
